/*RESPONSIVE CSS*/

/*Tab Potrait*/
@media only screen and (min-width:768px) and (max-width:980px) {}

/*Tab Landscape*/
@media only screen and (min-width:981px) and (max-width:1024px) {
    .login-desktop{
        display: none;
    }
    .login-tab{
        display: block;
    }

}

@media only screen and (min-width:480px) and (max-width:768px) {
    .login-desktop{
        display: none;
    }
    .login-tab{
        display: block;
    }

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    
}

/* Mobile Potrait */
@media (max-width:479px) {}

/* Mobile Landscape */
@media only screen and (min-width:480px) and (max-width:767px) and (orientation:landscape) {}

/*Mobile All and Tab Potrait*/
@media (max-width:980px) {
    .login-desktop{
        display: none;
    }
    .login-tab{
        display: block;
    }
    .doubled + .doubled {
        margin-left: 0;
    }
}

/*Mobile All*/
@media (max-width:767px) {}

/*Desktop Only*/
@media (min-width:1025px){
    .popover-form {
        width: 900px;
    }
}

/*Desktop Only*/
@media (min-width:1920px){
    .mainHeading{
        font-size: 1.5rem;
    }
    .MuiFormGroup-root.radio-group.MuiFormGroup-row legend, .MuiFormGroup-root.radio-group.MuiFormGroup-row .MuiTypography-body1 {
        font-size: 16px;
    }
    .swal2-styled.swal2-confirm,.swal2-styled.swal2-deny,.swal2-styled.swal2-cancel {
        font-size: 18px ;
    }
    .swal2-popup.swal2-modal div#swal2-html-container{
        font-size: 16px;
        text-align: center;
    }
    .MuiFormGroup-root.RadioGroup> legend {
        font-size: 16px!important;
    }
}