:root {
  --yellowOrange: #6ac5da;
  --brightOrange: #6ac5da;
  --redOrange: #fb5801;
  --brightTeal: #3db5d2;
  --lightTeal: #a601db;
  --greyTeal: #a789be;
  --lightGrey: #a7aba9;
  --mediumGrey: #8a8092;
  --darkGreyFont: #424b5c;
  --darkGrey: #494a52;
  --darkBlack: #000000;
  --white: #ffffff;
  --lightOrangeCustom: #fff3e1;
  --lightGreyCustom: #f6f6f6;
  --hoverLightGreyCustom: #eef2f3;
  --fontOrangeCustom: #ff9900;
  --hoverOrangeCustom: #fff1dc;
  --lightBluetooltipBg: #eef9ff;
  --lightBluetooltipBorder: #47a8f5;
  --errorColor: #f44336;
  --customGrey: #eef2f3;
  --customGreen: #008000;
  --customBlue: #0000ff;
  --lightBgOrange: #fbf4e5;
  --lightBgOrangeBRD: #f9b61d;
  --redColor: #fb5801;
  --btnTxtBlue: #0a66c2;
  --btnHoverBlue: #e3ecf5;
  --btnTextDisabled: #555454;
  --btnPrimaryDisabled: #eeeeee;
  --breadcrumColor: #3c66c4;
  --breadcrumColorSelected: #424b5c;
}

@font-face {
  font-family: "Lato-light";
  src: local("Lato Light"),
    url(../assests/fonts/Lato-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-regular";
  src: local("Lato Regular"),
    url(../assests/fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-bold";
  src: local("Lato Bold"),
    url(../assests/fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-medium";
  font-weight: 500;
  src: local("Lato Medium"),
    url(../assests/fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-semi-bold";
  font-weight: 600;
  src: local("Lato Semi Bold"),
    url(../assests/fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "FiraSans-Regular";
  font-weight: 600;
  src: local("Fira Sans Regular"),
    url(../assests/fonts/FiraSans-Regular.ttf) format("truetype");
}

* {
  font-family: "FiraSans-Regular", sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

#swal2-html-container {
  font-size: 0.875rem;
  font-weight: 600;
  font-family: "Lato-semi-bold", sans-serif;
  margin: 0;
  text-align: left;
  color: var(--darkGreyFont);
  padding: 0;
}

/* .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
    font-weight: bold;
    resize: horizontal;
    overflow-x: overlay;
    overflow-y: hidden;
    border-right: 1px solid;
} */
.pointeEvents {
  pointer-events: none;
  border: none;
}

.MuiCheckbox-root {
  pointer-events: auto;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f1f1f1;
}

.MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: #f1f1f1;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c1c1c1;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f1f1f1;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c1c1c1;
}

.MuiGrid-item {
  width: 100%;
}

.MuiFormHelperText-root.Mui-error {
  margin: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea {
  resize: none;
}

.MuiInputBase-root.Mui-disabled {
  background: rgb(0 0 0 / 2%);
}

div[role="tabpanel"] {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.mandatory-login {
  color: #fb5801;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  font-family: "Lato-regular", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.popup-Page .appBar {
  background: white;
}

.disabled .disabled {
  color: #8080807a;
  pointer-events: none;
}

.disabled {
  color: #8080807a;
  pointer-events: none;
}

.disabled1 .disabled1 {
  color: #8080807a;
  pointer-events: none;
}

.enabled {
  color: #3db5d2;
  pointer-events: auto;
}

.disabled1 .enabled {
  color: #3db5d2;
  pointer-events: auto;
}

.MuiCheckbox-root svg {
  color: #000000;
  font-size: 1.25em;
}

.MuiCheckbox-root.Mui-checked svg {
  color: #0a66c2 !important;
  font-size: 1.25em;
}
.checkboxInput .Mui-disabled svg {
  color: var(--btnPrimaryDisabled) !important;
}
.MuiFormGroup-root.RadioGroup label.Mui-disabled,
.MuiFormGroup-root.RadioGroup label.Mui-disabled span.Mui-disabled {
  background: none !important;
}
table tr td label {
  display: flex;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  height: 100%;
  white-space: nowrap;
}

table tr td label span {
  display: block;
}

table th:nth-child(1) {
  min-width: 30px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: black;
}

.login-tab {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.adjustBox {
  height: 24px;
  margin: -14px 0 0 10px;
}

html .MuiPickersToolbar-toolbar {
  background: #6ac5da;
}

html .MuiPickersDay-daySelected {
  background-color: #6ac5da;
}

.ethnicity-Page #menu-.MuiPopover-root,
.ethnicity-Page #menu-.MuiPopover-root > div {
  max-height: 0px;
}

.doubled + .doubled {
  margin-left: 20px;
}

html .disabled .MuiCheckbox-root svg {
  color: rgb(0 0 0 / 30%);
}

/* alert popup swal ui-css */
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: var(--yellowOrange);
  color: var(--darkBlack);
  width: 120px;
  font-family: "Lato-regular", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.swal2-actions {
  width: 100%;
  justify-content: end;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none;
}

.swal2-styled.swal2-deny {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em;
  width: 120px;
}

.swal2-styled.swal2-cancel {
  border-radius: 0.25em;
  background: initial;
  color: #3db5d2 !important;
  border: 1px solid #3db5d2 !important;
  font-size: 1em;
  width: 120px;
}

iframe:not(.iframePowerBI) {
  display: none;
}

html .disabled .disabled {
  color: var(--btnTextDisabled);
  background-color: var(--btnPrimaryDisabled);
  border: 2px solid transparent;
}

html .disabled .disabled span {
  color: #8080807a;
}

.asterisk {
  padding-left: 3px;
  font-size: 1.25em;
  color: #fb5801;
  position: absolute;
  top: -4px;
}

.hoverTextAccess {
  padding-left: 3px;
  font-size: 1.25em;
  color: black !important;
  background: white;
  position: absolute;
  top: -4px;
}

html .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #fb5801;
}

html .error-txtarea {
  border-color: #fb5801;
}

.swal2-container.swal2-center.swal2-noanimation {
  z-index: 9999;
}

.swal2-popup.swal2-modal {
  padding: 24px;
  min-width: 320px;
  width: auto;
}

/* Register color update */

tr[data-color="CourseGreen"],
tr[data-color="CourseGreen"]:hover {
  background-color: RGB(100, 200, 150) !important;
}

.icon-disabled {
  pointer-events: none;
  color: #877f7f !important;
}

button[disabled] span {
  color: #877f7f !important;
}

.MuiFormGroup-root.RadioGroup {
  flex-direction: revert;
}

tr[data-color="CourseBlue"],
tr[data-color="CourseBlue"]:hover {
  background-color: RGB(142, 255, 255) !important;
}

tr[data-color="CourseTan"],
tr[data-color="CourseTan"]:hover {
  background-color: RGB(204, 204, 153) !important;
}

.genericModalChildrenText p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.genericModalChildrenText li {
  color: rgba(0, 0, 0, 0.67);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.titleHelpIcons {
  top: 3px;
  color: yellow;
  cursor: pointer;
  position: relative;
  margin-left: 6px;
  border-radius: 30px;
  background-color: black;
}

.unofficial-btn button[disabled] span {
  color: grey;
}

.meetingCourseTime {
  list-style-type: none;
  font-weight: bold;
}

.meetingCourseTime span {
  padding-left: 25px;
}

.meetingCourseTime span:first-child {
  padding-left: 0;
}

.meetingCourseTime li {
  margin-bottom: 10px;
}

.padZero {
  padding-bottom: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

body.loader-external {
  background: #fff;
  overflow: hidden;
  position: relative;
}

body.loader-external-api {
  background: #fff;
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.api-request-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  width: 40px;
  height: 40px;
  margin: -38px 0 0 -38px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #6ac5da;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.report-nxt-btn {
  display: flex;
  justify-content: flex-end;
}

.Mui-disabled {
  background: var(--btnPrimaryDisabled) !important;
  color: var(--btnTextDisabled) !important;
  border-color: var(--btnPrimaryDisabled) !important;
}

/* Heading Style */

h1.MuiTypography-h4.mainHeading {
  font-weight: 600;
  /* color: var(--darkGreyFont); */
  font-family: "Lato-semi-bold", sans-serif;
  color: #646569;
}

.MuiFormGroup-root.radio-group.MuiFormGroup-row legend,
.MuiFormGroup-root.radio-group.MuiFormGroup-row .MuiTypography-body1 {
  font-family: "Lato-regular", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: var(--darkGreyFont);
}

th span strong,
h1,
h2 {
  color: var(--darkGreyFont);
}

.searchLanding {
  font-size: 1.125rem;
  font-family: "Lato-semi-bold", sans-serif;
  font-weight: 600;
  color: var(--darkGreyFont);
}

.checkboxInput .Mui-disabled {
  background: none !important;
}

td label {
  color: var(--darkGreyFont);
}

.scroll-tab-page .MuiTabs-scroller {
  flex: auto;
  width: 100%;
}

.not-scroll-tab-page .MuiTabs-scroller {
  flex: none;
  width: auto;
}

.scroll-tab-page .MuiBox-root .MuiCardContent-root,
.not-scroll-tab-page .MuiBox-root .MuiCardContent-root {
  padding: 16px 20px 16px 20px;
}

button.MuiButton-contained,
button.MuiButton-contained:hover {
  box-shadow: none;
}

nav .material-icons-outlined {
  font-size: 24px;
}

.register-Page .MuiTableHead-root th {
  padding-right: 20px;
}
/* .register-Page .MuiTableHead-root th:first-child {
    padding-right: 0px;
} */
.MuiFormGroup-root.RadioGroup > legend {
  font-size: 12px !important;
  font-family: "Lato-regular", sans-serif;
  color: var(--darkGreyFont);
  font-weight: 700;
}

.MuiFormGroup-root.RadioGroup label {
  margin-bottom: 0;
}
span.MuiFormControlLabel-label {
  margin-right: 10px;
}

.semaphore-initiate-msg {
  padding-bottom: 15px;
  color: var(--redOrange);
}

/* Ferpa content css starts*/
.ferpa_content ul li {
  padding-bottom: 16px;
  list-style-type: none;
}

.ferpa_content {
  font-size: 14px;
  text-align: left;
  margin-top: 40px;
  list-style: none;
  color: var(--darkGreyFont);
}
/* Ferpa content css starts*/
.swal2-popup.swal2-modal div#swal2-html-container {
  font-size: 16px;
  text-align: center;
}

.main-radio legend {
  display: none;
}

.refer svg[data-testid="RefreshIcon"] {
  color: #ff9900;
  cursor: pointer;
  padding-left: 5px;
  font-size: 1.25em;
}

/* jump to page css starts here*/

p.jumpToPageParent form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

p.jumpToPageParent form span {
  padding-right: 15px;
}

p.jumpToPageParent form label {
  display: none;
}

p.jumpToPageParent form input {
  padding: 3px !important;
  text-align: center;
  width: 70px;
}

p.jumpToPageParent {
  flex: 1 1 0;
  padding: 0 10px;
}

p.jumpToPageParent button {
  padding: 5px;
  background: #6ac5da;
  border: 0;
  border-radius: 5px;
  width: 50px;
  font-size: 14px;
}

tr.MuiTableRow-root.Mui-selected.MuiTableRow-hover {
  background-color: #e6f5f9;
}

html .MuiPickersToolbarText-toolbarBtnSelected {
  color: black;
}

html .MuiPickersToolbarText-toolbarTxt {
  color: black;
}

/* Calendar day, month and year font sizes  */
html .MuiPickersBasePicker-container .MuiTypography-h4 {
  font-size: 24px;
}

html .MuiPickersCalendarHeader-switchHeader .MuiTypography-body1,
html .MuiPickersBasePicker-container .MuiTypography-subtitle1 {
  font-size: 18px;
}

html p.MuiTypography-root.MuiTypography-body1,
html .MuiPickersCalendarHeader-dayLabel {
  color: #424b5c;
}

html .MuiPickersDay-daySelected:hover {
  background-color: #6ac5da;
}

/* jump to page css ends here*/

.MuiListItem-button:hover,
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #e6f5f9 !important;
}

/* Breadcrum color */
html .MuiBreadcrumbs-root .MuiBreadcrumbs-li a {
  color: var(--breadcrumColor);
}
html .MuiBreadcrumbs-root .MuiBreadcrumbs-li p {
  color: var(--breadcrumColorSelected);
}
html .MuiBreadcrumbs-root .MuiBreadcrumbs-li a:hover {
  text-decoration: underline;
}

/* Textfield background color */
.MuiFormControl-root.MuiTextField-root.filled input {
  background: #e6fbfc;
}

.filled .MuiOutlinedInput-root.Mui-focused input {
  background: white;
}

.MuiTableRow-root.MuiTableRow-hover.Checked-State {
  background-color: #e6f5f9;
}

.MuiRadio-root svg {
  color: #868585 !important;
  font-size: 1em;
}

.MuiRadio-root.Mui-checked svg {
  color: #3c66c4 !important;
  font-size: 1em;
}

.MuiRadio-root:hover svg {
  color: #0a66c2 !important;
  font-size: 1em;
}

.MuiRadio-root.Mui-disabled svg {
  color: #a7a8a9 !important;
  font-size: 1em;
}

.btn-disable {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: #c0c0c0 !important;
  background-color: #ffffff !important;
}

.actionBtn {
  color: #000 !important; /* using important to overwrite material UI color*/
}

.disable-action .actionBtn {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: #c0c0c0 !important ;
  background-color: #ffffff !important ;
}

.ids-btn {
  display: none !important;
}
.tableCell {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.Mui-focused.Mui-focused {
  transform: translate(11px, -6px) scale(1);
}
span.displayValue {
  line-height: 52px;
  padding: 5px 12px;
  margin-left: -14px;
  background-color: #65D568;
  color: #ffffff;
  border-radius: 11px;
}
.ql-snow * {
  font-family: sans-serif;
}
.ql-container.ql-snow.ql-disabled {
  background-color: #efefef;
}