:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #ff9900;
  --toastify-color-success: #379d00;
  --toastify-color-warning: #e74c3c;
  --toastify-color-error: #e74c3c;
  --toastify-background-info: #fbf1e2;
  --toastify-background-success: #e7f2e2;
  --toastify-background-warning: #fbebe2;
  --toastify-background-error: #fbebe2;

  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: lato;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /* //Used only for colored theme */
  /* --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff; */

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #379d00,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #e74c3c
  );
  /* // Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
/* react-toastify override css style properties */
.Toastify__toast--success {
  border: 1px solid #65D568!important;
  border-left: 4px solid #65D568 !important;
  background-color: #f0fbf0 !important;
  border-radius: 0px !important;
  color: #424b5c;
}
.Toastify__toast--info {
  border: 1px solid #079ec2 !important;
  border-left: 4px solid #079ec2 !important;
  background-color: #E6FBFC !important;
  color: #424b5c;
}
.Toastify__toast--warning {
  border: 1px solid var(--toastify-color-warning) !important;
  border-left: 4px solid var(--toastify-color-warning) !important;
  background-color: var(--toastify-background-warning) !important;
}
.Toastify__toast--error {
  border: 1px solid var(--toastify-color-warning) !important;
  border-left: 4px solid var(--toastify-color-warning) !important;
  background-color: var(--toastify-background-error) !important;
}
.Toastify__toast {
  font: "lato-regular", sans-serif 14px bold !important;
  color: black;
}
.Toastify__close-button > svg {
  fill: #646569 !important;
  height: 12px !important;
  width: 12px !important;
}

.Toastify__close-button--light {
  color: #646569 !important;
  opacity: 1 !important;
}