body {
    /* SurveyJS Creator V2 */
    --primary: rgb(106, 197, 218);
    --primary-light: rgba(26, 183, 250, 0.1);
    --secondary: rgb(106, 197, 218) !important;;
}

:root {
    --button-opacity: 50%;
}



.svc-tabbed-menu-item--selected {
    border: 1px solid rgb(106, 197, 218) !important;
    background: #E6F5F9!important;
    border-bottom: 0px;
    box-shadow: none!important;
}

.svc-tabbed-menu-item .svc-tabbed-menu-item__text {
    font-size: 14px;
    font-family: "FiraSans-Regular";
    font-weight: 600;
}

.svc-text--normal{
    color: #424B5C!important;
}

.svc-text--bold{
    color: #000000!important;
}

.svc-tabbed-menu-item:hover {
    background-color: #E6F5F9!important
}

.sv-action-bar-item--pressed:not(.sv-action-bar-item--active) {
    opacity: var(--button-opacity) !important;
}

.svc-flex-row.svc-full-container {
    height: calc(80*(var(--sjs-base-unit, var(--base-unit, 8px))));
    min-height: calc(80*(var(--sjs-base-unit, var(--base-unit, 8px))));
    flex-grow: 1;
    position: relative;
}
#scrollableDiv-survey-resopnses {
    display: block !important;

}

.sd-header__text .sd-title, .svc-toolbox__item-title, .spg-question__title, .sv-string-editor {
    color: #424B5C !important;
}

.sv-action-bar-item--active {
    .sv-action-bar-item__icon use {
      fill:  rgb(106, 197, 218) !important;;
    }
  }


.sd-title.sd-container-modern__title {
    box-shadow: 0px 2px 0px  rgb(106, 197, 218) !important;;  
}
  
.svc-side-bar__container-header {
    box-shadow: inset 0px -2px 0px rgb(106, 197, 218);
}
.sd-boolean__thumb {
    color: rgb(0, 0,0) !important;
}


.sd-boolean__label {
  color: rgb(85,84,84) !important;
  font-family: inherit !important;
}
.sv-tagbox__item {
    background-color:rgb(106, 197, 218) !important;
}

.spg-question__content, .spg-question__content:hover {
    color: rgb(106, 197, 218) !important;
}

.sd-input,.sd-input:focus,.spg-input:focus {
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0 0 0 0px  rgb(106, 197, 218) !important;
}
.sd-boolean, .sd-boolean:focus, .sd-boolean--allowhover {
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0 0 0 0px  rgb(106, 197, 218) !important;
}

.sv-action-bar {
    margin-left: 0px !important;
}

.sd-file__decorator--error {
    background-color: rgba(251, 88, 1, 0.1) !important;
}
.spg-question__erbox-icon use{
    fill: #FB5801 !important;
}
.sd-question--error{    
    box-shadow: 0 0 0 1px #FB5801 !important;
}

.spg-input.spg-input--error {
    box-shadow: 0 0 0 1px #FB5801 !important;
}
.sd-error {
    color: #FB5801 !important;
}
.spg-question__erbox {
    background-color: unset;
    padding-left: 0;
    color: var(--ctr-editor-border-color-error, var(--sjs-special-red, var(--red, #FB5801)));
}
.spg-question__erbox-icon {
   display: none;
}
 /*  
.svc-tabbed-menu, .svc-tabbed-menu-item, .svc-tabbed-menu-item--selected{
    height: calc(8*(6px));
}

.svc-tabbed-menu-item-container {
    height: calc(8*(6px));
}
*/